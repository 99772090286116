import React from 'react';

export const InfoRowHeightContext = React.createContext(0);
export const InfoRowElementSetterContext = React.createContext(0);

export const InfoRowHeightContextController = ({ children }) => {
  const [element, setElement] = React.useState();
  const [height, setHeight] = React.useState();

  React.useEffect(() => {
    if (!element) {
      setHeight(0);
      return () => {};
    }

    const handleResize = () => setHeight(element.getBoundingClientRect().height);

    if (ResizeObserver) {
      const resizeObserver = new ResizeObserver(handleResize);

      resizeObserver.observe(element);

      return () => resizeObserver.disconnect();
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [element]);

  return (
    <InfoRowElementSetterContext.Provider value={setElement}>
      <InfoRowHeightContext.Provider value={height}>{children}</InfoRowHeightContext.Provider>
    </InfoRowElementSetterContext.Provider>
  );
};
