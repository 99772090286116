const React = require('react');
const smoothscroll = require('smoothscroll-polyfill');

const { InfoRowHeightContextController } = require('./src/components/NavigationBar/InfoRowHeightContext');
const site = require('./site.json');
const getLLHeight = require('./src/helpers/getLLHeight').default;
const getNavHeight = require('./src/helpers/getNavHeight').default;
require('./src/fonts/fonts.css');
require('./src/pages/grid.min.css');

smoothscroll.polyfill();

/* Polyfill for ie >= 9 - Element.closest & Element.matches */

if (Element && !Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector; // jshint ignore:line
}

if (Element && !Element.prototype.closest) {
  Element.prototype.closest = (s) => {
    let el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

const getOffset = (elem) => {
  let offset = 0;
  if (
    site &&
    site.pages &&
    site.pages[0] &&
    site.pages[0].sections &&
    site.pages[0].sections[0] &&
    site.pages[0].sections[0].styles &&
    site.pages[0].sections[0].styles.fixed_top
  ) {
    const { logoHeight, linkHeight } = getLLHeight(site.logo, site.themeData);
    let infoRowHeight = 0;
    if (site.pages[0].sections[0].styles.infoRow && site.pages[0].sections[0].styles.infoRow.active) infoRowHeight = 56;
    const navHeight = getNavHeight(
      infoRowHeight,
      logoHeight,
      linkHeight,
      site.pages[0].sections[0].styles.layout,
      true,
    );

    offset =
      (site.pages[0].sections[0].styles.secondaryNavigation?.active &&
      site.pages[0].sections[0].styles.layout === 'center'
        ? -40
        : 0) - navHeight;
  }
  const eleTop = elem.getBoundingClientRect().top;
  const scrollOffset =
    window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || elem.scrollTop;

  const position = eleTop + scrollOffset + offset;
  return position < 0 ? 0 : position;
};

const scroll = (hash, smooth) => {
  if (document.readyState === 'complete') {
    const elem = document.getElementById(hash);
    if (elem) {
      if (smooth) {
        const target = getOffset(elem);
        window.scrollTo({
          top: target,
          behavior: 'smooth',
        });
      } else {
        setTimeout(() => {
          window.requestAnimationFrame(() => {
            const target = getOffset(elem);
            window.scrollTo({
              top: target,
              behavior: 'auto',
            });
          });
        }, 80);
      }
    }
  } else {
    setTimeout(() => scroll(), 1000);
  }
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location && prevLocation && location.hash) {
    scroll(location.hash, location.pathname === prevLocation.pathname);
  }
};

exports.shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  return (
    routerProps &&
    prevRouterProps &&
    routerProps.location &&
    prevRouterProps.location &&
    routerProps.location.pathname !== prevRouterProps.location.pathname
  );
};

exports.wrapRootElement = ({ element }) => React.createElement(InfoRowHeightContextController, {}, element);
