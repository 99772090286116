const getLLHeight = (logo, themeData) => {
  let logoHeight;
  let linkHeight = 34;
  if (logo && logo.active) logoHeight = logo.logoHeight !== undefined ? logo.logoHeight : 60;
  else {
    let lineHeight = 1.5;
    let size = 38;
    if (themeData && themeData.typography && themeData.typography.logo) {
      lineHeight = themeData.typography.logo.lineHeight;
      size =
        themeData.typography.logo.fontSize === 'Large'
          ? 50
          : themeData.typography.logo.fontSize === 'Small'
          ? 30
          : size;
    }

    logoHeight = size * lineHeight;
  }

  if (themeData && themeData.typography && themeData.typography.navigation) {
    linkHeight =
      themeData.typography.navigation.fontSize === 'Small'
        ? 30
        : themeData.typography.navigation.fontSize === 'Large'
        ? 40
        : linkHeight;
  }

  return { logoHeight, linkHeight };
};

export default getLLHeight;
